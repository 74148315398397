import { AxiosResponse } from 'axios';
import {ENTITY_CLIENT} from "@/api/misc/EntityClient";
import {ContactInquiry} from "@/enum/ContactInquiry.enum";

/**
 * repository that provides all the api requests that deal with ContainerType
 */
export default class ContactInquiryRepository {
    private static baseUrl: string = 'api/contact';

    /**
     * gets a company object by the id
     */
    public static postInquiry(payload: {type: string, description: string}): Promise<AxiosResponse> {
        return ENTITY_CLIENT.post(`${this.baseUrl}`, payload);
    }
}
