
import {Component} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ErrorMessageHandlerMixin from "@/misc/ErrorMessageHandler.mixins";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import {ContactInquiry} from "@/enum/ContactInquiry.enum";
import ContactInquiryRepository from "@/api/repositories/ContactInquiryRepository";
import GDPRRepository from "@/api/repositories/GDPRRepository";
import AccountDeletionRepository from "@/api/repositories/AccountDeletionRepository";

@Component({
  mixins: [validationMixin],
  validations: {
    subject: {required},
    text: {}
  }
})
export default class ContactDialogComponent extends mixins(ErrorMessageHandlerMixin) {
  private subject: ContactInquiry | null = null;
  private text = '';
  private isLoading = false;

  private subjectItems = [
    {label: this.$t('CONTACT_INQUIRY.ISSUE'), value: ContactInquiry.ISSUE},
    {label: this.$t('CONTACT_INQUIRY.OTHER'), value:  ContactInquiry.OTHER},
    {label: this.$t('CONTACT_INQUIRY.GET_DATA'), value:  ContactInquiry.GET_DATA},
    {label: this.$t('CONTACT_INQUIRY.DELETE_DATA'), value:  ContactInquiry.DELETE_DATA},
  ];

  private dismiss(reload: boolean = false) {
    this.$v.$reset();
    this.$emit('closeDialog', reload);
  }

  private get showTextArea(): boolean {
    return this.subject === ContactInquiry.ISSUE || this.subject === ContactInquiry.OTHER;
  }

  private async send() {
    this.$v.$touch();
    if (this.$v.invalid) {
      return;
    }

    try {
      this.isLoading = true;

      // checks if the user just wants to get his data
      if(this.subject === ContactInquiry.GET_DATA) {
        await GDPRRepository.getData();
        this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.CONTACT_DIALOG_GET_DATA_SUCCESS');
        this.dismiss();
        return;
      }

      if(this.subject === ContactInquiry.DELETE_DATA) {
        await AccountDeletionRepository.deleteAccount();
        this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.CONTACT_DIALOG_DELETE_DATA_SUCCESS');
        this.dismiss();
        return;
      }

      if(!this.text) {
        this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.CONTACT_FORM_MISSING_TEXT');
        return;
      }
      const payload = {
        type: this.subject!.toString(),
        description: this.text
      };
      await ContactInquiryRepository.postInquiry(payload);
      this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.CONTACT_DIALOG_SEND_ISSUE_SUCCESS');
      this.dismiss();
    } catch(e) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.CONTACT_DIALOG_ERROR');
    } finally {
      this.isLoading = false;
    }
  }
}
