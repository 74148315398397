import { AxiosResponse } from 'axios';
import {ENTITY_CLIENT} from "@/api/misc/EntityClient";

/**
 * deals with all the requests that have to do with accounts
 */
export default class GDPRRepository {
    /**
     * gets the export data
     */
    public static getData(): Promise<AxiosResponse> {
        return ENTITY_CLIENT.post(`api/gdpr/export`);
    }
}
